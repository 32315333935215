import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Main, { MainSelection } from './pages/Main';
import CannotThinkThrough from './pages/originals/cannot-think-through';
import NextChapter from './pages/originals/next-chapter';
import ScrollToTop from './pages/ScrollToTop';

const ALLOWED_HOSTNAME = [
  "localhost",
  "wrapmelove.com",
  "www.wrapmelove.com",
  "dmy45uglm1txw.cloudfront.net"
]

if (!ALLOWED_HOSTNAME.includes(window.location.hostname)) {
  window.location.href = "/error.html";
} else {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <Switch>
  
          <Route path="/home">
            <Main selection={MainSelection.Originals} />
          </Route>
  
          <Route path="/">
            <Redirect to="/home" />
          </Route>
        </Switch>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
